import React from 'react';
import * as messageTypes from './message-types';
import {
  MESSAGE_ERROR,
  MESSAGE_IMPORTANT,
  MESSAGE_INFO,
} from '../../../common/messages/framework/messages-types';
import ActivateSubscriptionsLink from '../../containers/activate-subscriptions-link';
import Interpolate from '../../../common/components/interpolate/interpolate';
import { TranslationWithHtmlTags } from '../../../common/components/translation-with-html-tags/translation-with-html-tags';

export default {
  [messageTypes.ASSETS_ARE_UPLOADING]: {
    content: () => <TranslationWithHtmlTags tKey="post-form.uploading" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DELETE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-deleted" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UPDATE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-updated" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_INVALID]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-invalid" />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_TOO_LARGE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-too-large" />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_ENABLE_COMMENTING]: {
    content: () => <TranslationWithHtmlTags tKey="messages.commenting-enabled" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_ENABLE_ANSWERING]: {
    content: () => <TranslationWithHtmlTags tKey="messages.answering-enabled" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DISABLE_COMMENTING]: {
    content: () => <TranslationWithHtmlTags tKey="messages.commenting-disabled" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DISABLE_ANSWERING]: {
    content: () => <TranslationWithHtmlTags tKey="messages.answering-disabled" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_PIN]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-pinned" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UNPIN]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-unpinned" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.USER_SUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.user-subscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.USER_UNSUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.user-unsubscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_SUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-subscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UNSUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-unsubscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_MOVE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-moved" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.ANSWER_UPDATE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.answer-updated" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.REPLY_UPDATE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.reply-updated" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.ANSWER_DELETE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.answer-deleted" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.REPLY_DELETE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.reply-deleted" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.REQUIRE_POST_FIELDS]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-fields-required" />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.CATEGORY_DELETE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.category-deleted" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UPDATE]: {
    content: () => <TranslationWithHtmlTags tKey="messages.category-updated" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_SUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.category-subscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UNSUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.category-unsubscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UNSUBSCRIBED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.category-unsubscribed" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_CREATED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.category-created" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.MAIN_PAGE_UPDATED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.main-page-updated" />,
    type: MESSAGE_INFO,
  },

  [messageTypes.RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY]: {
    content: () => <TranslationWithHtmlTags tKey="messages.rce-file-download-will-begin-shortly" />,
    type: MESSAGE_INFO,
  },
  [messageTypes.LINK_COPIED_SUCCESS]: {
    content: () => <TranslationWithHtmlTags tKey="share-link.link-copied" />,
    type: MESSAGE_IMPORTANT,
  },
  [messageTypes.LINK_COPIED_FAILURE]: {
    content: () => <TranslationWithHtmlTags tKey="share-link.link-copied-failure" />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_LINK_COPIED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-link-copied" />,
    type: MESSAGE_IMPORTANT,
  },
  [messageTypes.POST_LIKE_SUCCESS]: {
    content: () => <TranslationWithHtmlTags tKey="a11y-lives-messages.post-like-success" />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_CREATE_SUCCESS]: {
    content: () => <TranslationWithHtmlTags tKey="a11y-lives-messages.post-create-success" />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.CATEGORY_RANKS_UPDATE_SUCCESS]: {
    content: () => (
      <TranslationWithHtmlTags tKey="a11y-lives-messages.category-ranks-update-success" />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_TITLE_REQUIRED]: {
    content: () => <TranslationWithHtmlTags tKey="post-form.require-title" />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_CATEGORY_REQUIRED]: {
    content: () => <TranslationWithHtmlTags tKey="post-form.require-category" />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_UPLOAD_IN_PROGRESS]: {
    content: () => <TranslationWithHtmlTags tKey="post-form.uploading" />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.SEARCH_SEARCH_SUCCESS]: {
    content: (t, { posts }) => {
      const results = posts.length;
      if (results) {
        return t('a11y-lives-messages.search-results', { count: results });
      } else {
        return <TranslationWithHtmlTags tKey="a11y-lives-messages.search-no-results" />;
      }
    },
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_LIMIT_REACHED]: {
    content: () => <TranslationWithHtmlTags tKey="messages.post-limit-reached" />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.ACTIVATE_SUBSCRIPTIONS]: {
    content: () => (
      <Interpolate i18nKey="messages.activate-subscriptions" link={<ActivateSubscriptionsLink />} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.EXTERNAL_HOOK_REJECT]: {
    content: (t, { message }) => {
      return message.message ? message.message : message;
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.SITE_STORAGE_FULL]: {
    content: () => <TranslationWithHtmlTags tKey="messages.site-storage-full" />,
    type: MESSAGE_ERROR,
    showWithoutTimeout: true,
  },
  [messageTypes.ALL_CATEGORIES_SUBSCRIBED]: {
    content: (t) => (
      <TranslationWithHtmlTags
        dataHook={messageTypes.ALL_CATEGORIES_SUBSCRIBED}
        tKey="messages.all-categories-subscribed"
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.ALL_CATEGORIES_UNSUBSCRIBED]: {
    content: (t) => (
      <TranslationWithHtmlTags
        dataHook={messageTypes.ALL_CATEGORIES_UNSUBSCRIBED}
        tKey="messages.all-categories-unsubscribed"
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MARKED_ALL_AS_READ]: {
    content: (t) => (
      <TranslationWithHtmlTags
        dataHook={messageTypes.MARKED_ALL_AS_READ}
        tKey="messages.marked-all-as-read"
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MARKED_AS_READ]: {
    content: (t) => (
      <TranslationWithHtmlTags
        dataHook={messageTypes.MARKED_ALL_AS_READ}
        tKey="messages.marked-as-read"
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MARKED_AS_UNREAD]: {
    content: (t) => (
      <TranslationWithHtmlTags
        dataHook={messageTypes.MARKED_ALL_AS_READ}
        tKey="messages.marked-as-unread"
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_REPORTED]: {
    content: (t) => (
      <TranslationWithHtmlTags
        dataHook={messageTypes.POST_REPORTED}
        tKey="messages.post-reported"
      />
    ),
    type: MESSAGE_INFO,
  },
};
